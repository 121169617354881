<template>
  <section id="forms">
    <h2>Forms</h2>
    <f-input id="input" placeholder="Placeholder" label="Input" v-model="input"/>
    <f-input disabled id="input" placeholder="Placeholder" label="Input" v-model="input"/>
    <f-input error="Error message" placeholder="Placeholder" label="Input" v-model="input"/>
    <f-select label="Label" v-model="select">
      <option value="1">Option 1</option>
      <option value="2">Option 2</option>
      <option value="3">Option 3</option>
    </f-select>
  </section>
</template>
