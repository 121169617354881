<script setup>
import {computed, onMounted} from "vue";

const props = defineProps({
  size: {
    type: String,
    default: "md",
  },
  src: {
    type: String,
    required: true,
  },
  alt: {
    type: String,
    required: false,
  },
  rounded: {
    type: Boolean,
    default: false,
  },
  status: {
    type: String,
    default: null,
  },
});

const classes = computed(() => {
  let base = "inline-block";

  if (props.rounded) {
    base = `${base} rounded-full`;
  } else {
    base = `${base} rounded-md`;
  }

  switch (props.size) {
    case "sm":
      return `${base} h-6 w-6`;
    case "md":
      return `${base} h-8 w-8`;
    case "lg":
      return `${base} h-10 w-10`;
    case "xl":
      return `${base} h-12 w-12`;
    case "2xl":
      return `${base} h-16 w-16`;
  }
});

const statusClasses = computed(() => {
  const base = "absolute top-0 right-0 block h-2 w-2 rounded-full ring-2 ring-white"

  if (props.status) {
    switch (props.status) {
      case "online":
        return `${base} bg-green-400`;
      case "offline":
        return `${base} bg-gray-300`;
      case "away":
        return `${base} bg-yellow-400`;
      case "busy":
        return `${base} bg-red-400`;
    }
  }
})

</script>

<template>
  <span class="relative inline-block">
    <img :class="classes" :src="src" :alt="alt" />
    <span :class="statusClasses" v-if="status" />
  </span>
</template>
