<template>
  <div is="description">
    <h1 class="text-2xl font-semibold">FroeschkeUI</h1>
    <p class="my-2">
      FroeschkeUI is a component library for <a href="https://vuejs.org/">VueJS</a> written with <a href="https://tailwindcss.com/">TailwindCSS</a> and
      <a href="https://www.typescriptlang.org/">TypeScript</a>
    </p>
  </div>
  <div id="installation">
    <h2 class="text-lg font-semibold">Installation</h2>
    <!-- TODO -->
  </div>
</template>

<style>
  a {
    @apply text-blue-600 hover:underline;
  }
</style>