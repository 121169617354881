<script setup>
import {computed} from "vue";

const props = defineProps({
  color: {
    type: String,
    default: "blue",
  },
});

const classes = computed(() => {
  const base = "inline-flex items-center rounded-md gap-x-0.5 px-2 py-1 text-xs font-medium";
  switch (props.color) {
    case "gray":
      return {
        base: `${base} bg-gray-100 text-gray-800`,
        button: {
          base: "hover:bg-gray-500/20",
          cross: "stroke-gray-600/50 group-hover:stroke-gray-600/75"
        },
      };
    case "red":
      return {
        base: `${base} bg-red-100 text-red-800`,
        button: {
          base: "hover:bg-red-500/20",
          cross: "stroke-red-600/50 group-hover:stroke-red-600/75"
        },
      };
    case "yellow":
      return {
        base: `${base} bg-yellow-100 text-yellow-800`,
        button: {
          base: "hover:bg-yellow-500/20",
          cross: "stroke-yellow-600/50 group-hover:stroke-yellow-600/75"
        },
      };
    case "green":
      return {
        base: `${base} bg-green-100 text-green-800`,
        button: {
          base: "hover:bg-green-500/20",
          cross: "stroke-green-600/50 group-hover:stroke-green-600/75"
        },
      };
    case "blue":
      return {
        base: `${base} bg-blue-100 text-blue-800`,
        button: {
          base: "hover:bg-blue-500/20",
          cross: "stroke-blue-600/50 group-hover:stroke-blue-600/75"
        },
      };
    case "indigo":
      return {
        base: `${base} bg-indigo-100 text-indigo-800`,
        button: {
          base: "hover:bg-indigo-500/20",
          cross: "stroke-indigo-600/50 group-hover:stroke-indigo-600/75"
        },
      };
    case "purple":
      return {
        base: `${base} bg-purple-100 text-purple-800`,
        button: {
          base: "hover:bg-purple-500/20",
          cross: "stroke-purple-600/50 group-hover:stroke-purple-600/75"
        },
      };
    case "pink":
      return {
        base: `${base} bg-pink-100 text-pink-800`,
        button: {
          base: "hover:bg-pink-500/20",
          cross: "stroke-pink-600/50 group-hover:stroke-pink-600/75"
        },
      };
  }
});

// function that returns a boolean if the element has a click event attached to it without any parameters

</script>

<template>
  <span :class="classes.base">
    <slot />
    <button type="button" :class="['group relative -mr-1 h-3.5 w-3.5 rounded-sm', classes.button.base]">
      <span class="sr-only">Remove</span>
      <svg viewBox="0 0 14 14" :class="['h-3.5 w-3.5 stroke-gray-600/50 group-hover:stroke-gray-600/75', classes.button.cross]">
      <path d="M4 4l6 6m0-6l-6 6" />
      </svg>
      <span class="absolute -inset-1"></span>
    </button>
  </span>
</template>
