import { createApp } from 'vue';
import Dev from './serve.vue';
import { createRouter, createWebHistory } from 'vue-router';

import VueTailwindComponents from '@/entry.esm';
import Index from "./Pages/Index.vue";
import Avatars from "./Components/Avatars/Avatars.vue";
import Badges from "./Components/Badges/Badges.vue";
import Buttons from "./Components/Buttons/Buttons.vue";
import Dropdowns from "./Components/Dropdowns/Dropdowns.vue";
import Forms from "./Components/Forms/Forms.vue";

import 'highlight.js/styles/stackoverflow-light.css'
import hljs from 'highlight.js/lib/core';
import javascript from 'highlight.js/lib/languages/javascript';
import typescript from 'highlight.js/lib/languages/typescript';
import html from 'highlight.js/lib/languages/xml';
// @ts-ignore
import hljsVuePlugin from "@highlightjs/vue-plugin";
import Breadcrumbs from "./Components/Breadcrumbs/Breadcrumbs.vue";

hljs.registerLanguage('javascript', javascript);
hljs.registerLanguage('typescript', typescript);
hljs.registerLanguage('html', html);


const routes = [
    {
        path: '/',
        component: Index
    },
    {
        path: '/components',
        redirect: '/',
        children: [
            { path: 'avatars', component: Avatars },
            { path: 'badges', component: Badges },
            { path: 'buttons', component: Buttons },
            { path: 'dropdowns', component: Dropdowns },
            { path: 'forms', component: Forms},
            { path: 'breadcrumbs', component: Breadcrumbs }
        ]
    },
];

const router = createRouter({
    history: createWebHistory(),
    routes,
})

const app = createApp(Dev);

app.use(VueTailwindComponents);
app.use(router);
app.use(hljsVuePlugin)

app.mount('#app');
