<script setup>
import {ref} from "vue";

defineProps({
  id: {
    type: String,
    required: false,
  },
  label: {
    type: String,
    required: false,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  modelValue: {
    type: String,
  },
})

const input = ref(null)

defineEmits(["update:modelValue"])
</script>

<template>
  <div>
    <label :for="id" class="block text-sm font-medium text-gray-700" v-if="label">{{ label }}</label>
    <select :id="id" name="id" @input="$emit('update:modelValue', $event.target.value)" :disabled="disabled"
            class="mt-1 block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm">
      <slot />
    </select>
  </div>
</template>
